import React, { FC, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { handlePriceSpiderModalClose, removePriceSpider } from 'utils/externalServices/priceSpider';
import runOnUserInteraction from 'utils/runOnUserInteraction';
import { PriceSpiderProps } from './models';

const PriceSpider: FC<PriceSpiderProps> = ({ lang }) => {
  const [date, setDate] = useState(0);
  const [shouldPriceSpiderLoad, setShouldLoadPriceSpider] = useState(false);

  if (!process.env.GATSBY_PRICE_SPIDER_KEY) return null;

  useEffect(() => {
    removePriceSpider();
    setDate(Date.now());
    runOnUserInteraction(() => setShouldLoadPriceSpider(true));

    return () => {
      handlePriceSpiderModalClose();
      removePriceSpider();
    };
  }, [lang]);

  return (
    <Helmet>
      <meta name="ps-key" content={process.env.GATSBY_PRICE_SPIDER_KEY} />
      <meta name="ps-country" content={process.env.GATSBY_PRICE_SPIDER_COUNTRY} />
      <meta name="ps-language" content={lang.split('-')[0]} />
      {shouldPriceSpiderLoad ? (
        <script
          src={`${process.env.GATSBY_PRICE_SPIDER_SCRIPT_URL}?v="${date}"`}
          type="text/javascript"
          async
        />
      ) : null}
    </Helmet>
  );
};

export default PriceSpider;

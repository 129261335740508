import React, { FC } from 'react';

import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import { ProductsHeaderProps } from './models';

import './ProductsHeader.scss';

const ProductsHeader: FC<ProductsHeaderProps> = ({ productsTitle, productsDescription }) => {
  return productsTitle || productsDescription ? (
    <div className="products-header" data-testid="products-header">
      <h2 className="products-header__title">{productsTitle}</h2>
      <div className="products-header__description">
        <DangerouslySetInnerHtml html={productsDescription} />
      </div>
    </div>
  ) : null;
};

export default ProductsHeader;

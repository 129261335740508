import { removeElementById, removeElementsByClassName, removeJsOrCss } from '../browser';

// eslint-disable-next-line import/prefer-default-export
export const removePriceSpider = () => {
  if (window.PriceSpider) {
    removeJsOrCss('cdn.pricespider');
    removeJsOrCss('cdn.pricespider', false);
    removeElementsByClassName('ps-container');
    removeElementById('ps-lightbox-background');
    window.PriceSpider = null;
  }
};

export const handlePriceSpiderModalClose = () => {
  const priceSpiderModal = document.getElementsByClassName('ps-container ps-lightbox ps-open');
  if (priceSpiderModal) {
    document.documentElement.style.overflowY = '';
    document.body.style.overflowY = '';
  }
};

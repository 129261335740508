import React, { FC, useEffect, useState } from 'react';
import { BazaarVoiceProps } from './models';
import './BazaarVoice.scss';

const BazaarVoice: FC<BazaarVoiceProps> = ({
  eanCode,
  showOnlyRating = false,
  inlineRating = false,
  productURL = '',
}) => {
  const [productId, setProductId] = useState('0');

  useEffect(() => {
    setProductId(eanCode);
  }, [eanCode]);

  return (
    <div id="bazaar_voice" data-testid="bazaar voice">
      {showOnlyRating ? (
        <div
          className="rating-summary"
          data-bv-show="rating_summary"
          data-bv-product-id={productId}
        />
      ) : inlineRating ? (
        <div
          data-bv-show="inline_rating"
          data-bv-product-id={productId}
          data-bv-redirect-url={`${productURL}#reviews_section`}
          data-bv-seo="false"
        />
      ) : (
        <div className="reviews-summary" data-bv-show="reviews" data-bv-product-id={productId} />
      )}
    </div>
  );
};

export default BazaarVoice;
